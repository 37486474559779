.container {
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  height: 11rem;
  margin: 20px 0 10px 40px;
  border-radius: 5px;
  box-shadow: 0px 6px 12px -2px rgba(125, 125, 125, 1);
}
.container:hover {
  cursor: pointer;
  box-shadow: 0px 9px 20px 0px rgb(76, 75, 75);
  transition: 0.2s;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
}

.text {
  margin: 20px 0px 20px 20px;
  font-size: 1.3rem;
  font-weight: 900;
  color: #111111;
}

.text1 {
  margin: 20px 20px 20px 0;
  font-size: 1rem;
  color: #111111;
}

.x {
  font-size: 1.2rem;
}

.body {
  margin: 0px 5px 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
}

.textDefault {
  margin: 20px 20px 5px 0px;
  font-size: 1rem;
  color: #111111;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
