.input {
  outline: none;
  border: none;
  background: linear-gradient(
    106deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  padding: 16px 10px;
  max-height: 42px;
  color: rgba(243, 244, 248, 0.56);
  border-radius: 2px;
  margin-top: 2rem;
}

.input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: rgba(243, 244, 248, 0.56);
  text-transform: uppercase;
  line-height: 1rem;
}

.holder {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 293px;
}

.holder a {
  max-width: 180px !important;
  min-width: 110px;
  margin-inline: auto;
  padding: 0.5rem 1rem;
}

.email {
  background: linear-gradient(106deg, #007990 -14.95%, #f3f4f8 267.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.submitButton {
  display: inline-block;
  text-decoration: none;
  color: #f3f4f8;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(8, 139, 136, 0.7) 0%,
    rgba(106, 240, 206, 0.7) 100%
  );
  padding: 0.5rem 1.5rem;
  border-radius: 2px;
  margin-top: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
  max-width: 110px;
  margin-inline: auto;
}

.submitButton:hover {
  background: linear-gradient(90deg, #088b88 0%, #6af0ce 100%);
}
