.profileContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.modalContainer {
  margin: 1rem;
}
.allContainers {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nameContainer {
  display: flex;
}

.name,
.nameInput {
  margin: 0.2rem 0;
}
.name {
  min-width: 190px;
  font-size: 1.2rem;
}
.nameInput {
  padding: 0.7rem 1.2rem;
  border: none;
  border-radius: 8px;
  background-color: rgb(223, 221, 221);
  color: black;
}
.nameInput:focus {
  box-shadow: 0 0 6px 2px rgba(4, 16, 107, 0.358);
  outline: none;
}
.nameInput::placeholder {
  color: gray;
}

.profileButton {
  display: flex;
  padding: 0.7rem 1.4rem;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 6px 2px rgba(52, 54, 70, 0.27);
  cursor: pointer;
  background-color: transparent;
  color: rgb(52, 54, 70);
  transition: background-color 0.3s ease, color 0.3s ease;
}
.profilePic .optionsResult {
  margin-top: 2rem;
  animation: slideFromLeft 1s ease-in-out forwards;
}
.inputContainer {
  position: relative;
}
.hide {
  position: absolute;
  z-index: 8375;
  color: gray;
  cursor: pointer;
  right: 0;
  top: 0;
  margin-top: 1rem;
}
.hide:hover {
  color: black;
}
@keyframes slideFromLeft {
  0% {
    color: rgba(240, 248, 255, 0);
    transform: translateY(-40%);
  }
  100% {
    transform: translateY(0);
  }
}
/* Media Queries */

/* TABLET AND OTHERS */

@media (max-width: 1400px) {
  .profileContainer {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .allContainers {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
}
