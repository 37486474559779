.container {
  /*   background-color: #eeeeee; */
  background-image: linear-gradient(
      to bottom,
      rgba(17, 17, 17, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(to top, rgba(17, 17, 17, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  min-width: 0;

  height: 73vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.head {
  margin-top: 20px;
  margin-bottom: 6px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  font-weight: 200;
  color: white;
}

.text {
  left: 0;
  font-size: 1.3rem;
}
.rightside {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cancel {
  margin: 20px;
}
.procedura {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;
  font-size: 20px;
}
.procedura:hover {
  cursor: pointer;
  color: #757575;
}
.cancel:hover {
  cursor: pointer;
  color: #757575;
}
.procedura:hover > .circle {
  cursor: pointer;
  border: 3px solid#757575;
  background-color: #757575;
}

.circle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 0.5rem;
  height: 2rem;
  color: #eeeeee;
  font-weight: 200;
}
.circleText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #d4d4d4;
}

.body {
  width: 95%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.cont {
  width: 100%;
  height: 40vh;
  /*   overflow: hidden; */
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  z-index: 10;
  top: 0;
}

.bodyHeader {
  position: sticky;
  z-index: 1;
  margin: 30px 56px 4px 56px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  color: white;
}
.bodyHeader > div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 80%;
}
.bodyHeader > div > p {
  padding-left: 10px;
}

.scrollableContent {
  height: 50%;
  overflow: auto;
  overflow-y: auto;
  box-sizing: content-box;
  margin: 30px 28px 4px 28px;
}


/* Scrollbar track */
.scrollableContent::-webkit-scrollbar-track {
  background: linear-gradient(
    to bottom,
    #78FFD5 0%, /* Green at the top */
    #009FFF var(--scroll-gradient-size), /* Blue transitioning based on scroll */
    transparent var(--scroll-gradient-size)/* Blue at the bottom */
  );
  border-radius: 10px;
}
/* Scrollbar width */
.scrollableContent::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 80%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #2d2d32;
}

.pic {
  /*   width: 170px;
  height: 140px; */
  width: 7rem;
  height: 5rem;
  border: 1px solid #2d2d32;
  border-radius: 20px;

  object-fit: cover;
}

.descriptionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*   flex-grow: 1; */
  width: 72%;
  gap: 9%;
  /*  margin-right: 20px; */
}
.descriptionContainer > p {
  text-align: center;
  width: 8rem;
  color: white;
}
.footer {
  display: flex;
  justify-content: center;
  height: 30%;
  width: 100%;
}
.footerInner {
  width: 100%;
  display: flex;
  height: fit-content;
  overflow: hidden;
  justify-content: space-between;
}
.sessionSum {
  padding: 0 1.5rem;
  width: 35%;
  height: 100%;
  color: white;
  margin-top: 1rem;
}

.sumHeader {
  /*   margin: 20px 40px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bolder;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.summaryCoins {
  display: flex;
}

.coinImage {
  width: 1rem;
}
.format {
  height: fit-content;
  font-size: 0.65rem;
  padding: 0 0 10px 0;
}

.totalCoins {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid #cccccc;
  font-weight: 900;
  font-size: 0.85rem;
}
.one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*   margin: 20px 70px 0px 50px; */
}
.downloadButton {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 1rem 0 0 56px;
}
.btnContainer {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.regenerateButton {
  position: relative;
  border: none;
  outline: none;
  border-radius: 0 0 0.75rem 0.75rem;
  /*   background-color: #2d3648;
 */
  color: #fff;
  padding: 0.4rem 1rem;
  min-width: 6rem;
  font-size: 0.8rem;
  font-weight: 900;
  background-image: linear-gradient(
    to bottom right,
    rgb(29, 64, 71),
    rgb(49, 75, 80)
  );

  &:hover {
    cursor: pointer;
  }
}
.regenerateButton:hover {
  cursor: pointer;
  color: #151515;
  background-color: #d9d9d9;
  background-image: none;
  /*   background-color: rgb(45, 54, 72, 0.9);
 */
}
/* arka plani karartma */

.modalBackground {
  z-index: 92827368;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.proceed {
  right: 0;
  position: relative;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #2d3648;
  color: #fff;
  padding: 0.7rem 1rem;
}
.proceed:hover {
  cursor: pointer;
  background-color: rgb(45, 54, 72, 0.9);
}

.saveDownloadButtonsContainer {
  display: flex;
  gap: 1rem;
}

@media (min-width: 1750px) {
  .format {
    /*     font-size: 20px; */
    padding: 0 0 10px 0;
  }
  .pic {
    /*     width: 160px;
    height: 130px;
 */
  }
  .one {
    /*     margin: 10px 70px 0px 50px; */
  }
  .circle {
    /*     width: 2.1rem;
    height: 2.1rem; */
  }
  .circleText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #d4d4d4;
  }
  .bodyHeader {
    gap: 7rem;
  }
}
/*
@media (max-width: 1150px) {
  .bodyHeader > p {
    font-size: 16px;
  }

  .item > p {
    font-size: 16px;
  }
} */

@media (max-width: 1750px) {
  .footer {
    gap: 1rem;
  }

  .sessionSum {
    /*     width: 60%;
    padding: 0 1.5rem; */
  }
}
@media (max-width: 1590px) {
  .sessionSum {
    /*     width: 65%; */
  }
  .sumHeader {
    /*     margin: 10px 45px; */
  }
  .format {
    /*     font-size: 15px; */
    padding: 0 0 10px 0;
  }
  .pic {
    /*     width: 160px;
    height: 130px; */
  }
  .one {
    /*     margin: 5px 70px 0px 50px; */
  }
  .circle {
    /*     width: 2rem;
    height: 2rem; */
  }
  .circleText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #d4d4d4;
  }
}
@media (max-width: 1870px) {
  .sessionSum {
    /*     width: 60%; */
  }
  .sumHeader {
    /*     margin: 10px 45px; */
  }
  .format {
    /*     font-size: 15px; */
    padding: 0 0 10px 0;
  }
  .pic {
    /*     width: 160px; */
    /*     height: 130px; */
  }
  .one {
    /*     margin: 5px 70px 0px 50px; */
  }
  .circle {
    /*     width: 2rem;
    height: 2rem; */
  }
  .circleText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #d4d4d4;
  }
}
@media (max-width: 13400px) {
  .sessionSum {
    /*     width: 55%; */
  }
  .sumHeader {
    /*     margin: 10px 45px; */
  }
  .format {
    /*     font-size: 15px; */
    padding: 0 0 10px 0;
  }
  .pic {
    /*     width: 160px;
    height: 130px; */
  }
  .one {
    /*     margin: 5px 70px 0px 50px; */
  }
  .circle {
    /*     width: 2rem;
    height: 2rem; */
  }
  .circleText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #d4d4d4;
  }
}
@media (max-height: 850px) {
  .body {
    height: 30vh;
  }
  .bodyHeader {
    margin: 20px 20px 5px 85px;
  }
}
@media (max-height: 672px) {
  .body {
    height: 23vh;
  }
}