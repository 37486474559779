.settingContainer {
  display: flex;
  background-color: rgb(235, 230, 230);
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.settingModal {
  flex-wrap: wrap;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  padding: 1rem;
  border-radius: 10px;
  width: 80%;
}
.settingOptions {
  align-self: flex-start;
  margin: 1rem;
  display: flex;
  width: 100%;
}
.settingModal .myLabel {
  display: block;
  font-size: xx-large;
  font-weight: 900;
  color: rgb(52, 54, 70);
  border-bottom: 3px solid rgb(52, 54, 70);
  width: 100%;
}
.settingOptions button.buttons,
.settingOptions button.clickedButton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: larger;
  width: 400px;
  padding: 0.7rem 0.4rem;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 6px 2px rgba(52, 54, 70, 0.27);
  margin: 1rem 0;
  cursor: pointer;
  background-color: transparent;
  color: rgb(52, 54, 70);
  transition: background-color 0.3s ease, color 0.3s ease;
}
.settingOptions button.buttons:hover,
.settingOptions button.clickedButton {
  background-color: rgb(52, 54, 70);
  color: aliceblue;
}
.optionsResult {
  margin: 3rem 2rem;
  animation: slideFromLeft 0.7s ease-in-out forwards;
}

@keyframes slideFromLeft {
  0% {
    color: rgba(240, 248, 255, 0);
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Media Queries */

/* TABLET AND OTHERS */

@media (max-width: 1400px) {
  .settingContainer {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
  .settingModal {
    min-width: 90vw;
    max-width: 90vw;
    min-height: auto;
    padding: 1rem;
    margin: 100px auto 0;
    align-items: center;
    justify-content: center;
  }

  .optionsResult {
    padding: 1rem;
    margin: 0;
  }
  .settingOptions {
    flex-direction: column;
    align-self: center;
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
  @keyframes slideFromLeft {
    0% {
      color: rgba(240, 248, 255, 0);
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
