.container {
  width: 15vh;
  height: 15vh;
}

.containerRotate {
  width: 15vh;
  height: 15vh;
  animation: rotate infinite 3s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.image {
  width: 100%;
  height: 100%;
}
