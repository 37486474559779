.modalContainer {
  margin: 1rem;
  margin-top: 4rem;
}
@media (max-width: 1400px) {
  .modalContainer {
    margin-top: 0;
    margin-left: 7rem;
  }
}

.alert {
  width: 30%;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9999;
}
