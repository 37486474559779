.navContainer {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: rgb(52, 54, 70);
  color: aliceblue;
  height: 100%;
  overflow: auto;
  top: 0;
  right: 0;
  justify-content: space-between;
}

.logoContainer,
.companyName,
.options,
.nameAndSurname {
  display: flex;
  flex-direction: row;
}
.logoContainer,
.options,
.nameAndSurname {
  padding: 1.5rem;
}
.options,
.nameAndSurname {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: aliceblue;
}
.nameAndSurname p,
.options p {
  padding-left: 1rem;
  padding-top: 0.4rem;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.companyName label {
  font-size: 0.8rem;
  padding-left: 1rem;
  padding-top: 0.6rem;
}
.options p:hover {
  color: gray;
  cursor: pointer;
}
/* TABLET AND OTHERS */

@media (max-width: 1400px) {
  .navContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: rgb(52, 54, 70);
    color: aliceblue;
    height: auto;
    overflow: auto;
    top: 0;
    right: 0;
    justify-content: center;
    padding: 1.2rem;
    align-items: center;
  }
  .optionContainer {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
  }
  .options p {
    font-size: 0.7rem;
    margin-right: 0.3rem;
  }
  .logoContainer,
  .options,
  .nameAndSurname {
    padding: 0;
  }
  .nameAndSurname p,
  .options p {
    padding-left: 1rem;
    padding-top: 0.4rem;
  }
  .logo {
    display: flex;
    border: 1px solid white;
    align-items: center;
    justify-content: center;
  }
  .companyName label {
    font-size: 0.8rem;
    padding-left: 1rem;
    padding-top: 0.6rem;
  }
  .options p:hover {
    color: gray;
    cursor: pointer;
  }
}
