.All {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px 20px;
}
.userMessageContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* max-width: max-content; */
  max-width: 75%;
  float: right;
  border-radius: 15px;
  margin-bottom: 5px;
  overflow-y: auto;
  color: #fff;
  border-bottom-right-radius: 0;
  background-color: #111111;
  text-align: end;
  width: max-content;
  padding: 2% 5%;
}
.userMessageContainer p {
  /* padding: 13px 20px;
  min-width: 90px; */
  word-break: break-all;
}
.IconCont {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.icon {
  object-fit: cover;
  border-radius: 50%;

  width: 20px;
  height: 20px;
}
