.textContainer {
  margin: 20px 0 10px 30px;
  border-bottom: 1.7px solid #2d3648;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  width: 55%;
}
.text {
  padding: 0 0 2px 10px;
  font-size: 1.6rem;
  font-weight: 900;
  color: #2d3648;
}

.planContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}
