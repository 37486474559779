.container {
  background-color: #eeeeee;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  min-width: 0;

  height: 50vh;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px 0 30px;
}
.head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40%;
  gap: 1rem;
  margin: 20px 0px 0 0px;
}
.boldText {
  font-weight: 900;
}
.body {
  width: 90%;
  height: 60%;
  margin: 0px 0px 0px 0px;
}

.input {
  text-align: left;
  vertical-align: top;
  border: 1px solid #929191;
  border-radius: 5px;
  margin: 10px;
  width: 90%;
  height: 50%;
}
