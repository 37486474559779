.session {
  position: relative;
  bottom: 0px;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(51, 71, 69, 1) 35%
  );
}
.text {
  font-size: 13px;
  font-weight: 900;
  color: #b3b3b3;
  padding-left: 2rem;
}
