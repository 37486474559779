.button {
  color: #f3f4f8;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(8, 139, 136, 0.7) 0%,
    rgba(106, 240, 206, 0.7) 100%
  );
  outline: none;
  border: none;
  border-radius: 2px;
  min-height: 33px;
  padding: 0 33px;
  max-width: 110px;
  margin: 0 auto;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(90deg, #088b88 0%, #6af0ce 100%);
}

.input {
  outline: none;
  border: none;
  background: linear-gradient(
    106deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  padding: 16px 10px;
  max-height: 42px;
  color: rgba(243, 244, 248, 0.56);
  border-radius: 2px;
  margin-top: 2rem;
}

.input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: rgba(243, 244, 248, 0.56);
  text-transform: uppercase;
  line-height: 1rem;
}

.holder {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
