.input {
  outline: none;
  border: none;
  background: linear-gradient(
    106deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  padding: 16px 10px;
  max-height: 42px;
  color: rgba(243, 244, 248, 0.56);
  border-radius: 2px;
  margin-top: 1.5rem;
}

.input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: rgba(243, 244, 248, 0.56);
  text-transform: uppercase;
  line-height: 1rem;
}

.holder {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 293px;
}

.holder a {
  max-width: 180px !important;
  margin-inline: auto;
  padding: 0.5rem 1rem;
}
