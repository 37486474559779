.nameContainer {
  display: flex;
}
.name {
  margin: 0.2rem 0;
}
.name {
  min-width: 210px;
  font-size: 1.2rem;
  margin-right: 1rem;
}
