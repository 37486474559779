.sceneContainer {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  max-height: 30vh;
  height: "100%";
  width: "100%";
  position: relative;

  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgb(156, 156, 156) 0%,
    rgb(206, 206, 206) 0%,
    rgb(111, 111, 111) 0%,
    rgb(135, 135, 135) 0%,
    rgb(62, 62, 62) 0%,
    rgb(0, 0, 0) 100%
  );
}

.sceneLargeContainer {
  display: flex;
  flex-direction: column;
  height: "100%";
  width: "100%";
  position: relative;

  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 0%,
    rgb(156, 156, 156) 0%,
    rgb(206, 206, 206) 0%,
    rgb(111, 111, 111) 0%,
    rgb(135, 135, 135) 0%,
    rgb(62, 62, 62) 0%,
    rgb(0, 0, 0) 100%
  );
}
