.last {
	display: flex;
	flex-direction: column;
	height: 80vh;
	margin: 0.2rem;
	width: 35vw;
}
.last .lastButton {
	display: flex;
	justify-content: center;
	width: 100%;
	border: none;
	padding: 1rem;
	background-color: rgb(46, 47, 50);
	color: aliceblue;
	border-top-left-radius: 24px;
}
.last .lastButton:hover {
	box-shadow: 0 0 6px 2px rgb(104, 103, 103);
	cursor: pointer;
}
.children {
	border: 1px solid gray;
}
.children p {
	padding: 1rem;
	text-align: center;
	color: gray;
}
