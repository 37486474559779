.container {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00000000;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 80%;
}

.buttonContainerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 50%;
}

.icon {
  color: #78ffd5;
  transition: all 0.2s ease-in;
  scale: 0.85;
}
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  transition: all 0.2s ease-in;
  background-color: #111111;
  height: 3rem;
  width: 60%;
  border: 1px #111111 solid;
  border-radius: 0px 0px 20px 20px;
}
.button:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}
.button:hover > .icon {
  cursor: pointer;
  color: #151515;
}
.button:hover > .text {
  cursor: pointer;
  color: #151515;
}

.text {
  color: #f3f4f8;
  font-weight: 800;
  font-size: 12px;
  transition: all 0.2s ease-in;
}

.modal {
  position: absolute;
  top: 12.5%;
  left: 10%; 
 /*  width: 60%; */
  /* transform: translate(-50%, -50%); */
     background: #171717; 
   background-image: linear-gradient(
      to bottom,
      rgba(17, 17, 17, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(to top, rgba(17, 17, 17, 0.4) 0%, rgba(0, 0, 0, 0) 100%); 
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem 2rem;
 /*  overflow-y: auto; */
  max-height: 80%;
  min-width: 350px;
  max-width: 80%;
  border-radius: 8px;
  overflow: hidden;
}
.modalSettings {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  overflow-y: auto;
  max-height: 80%;
  min-width: 350px;
  max-width: 80%;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .modal {
    min-width: 290px;
  }
}

@media (max-width: 1800px) {
  .buttonContainer {
    gap: 2rem;
  }
}

@media (max-width: 1300px) {
  .buttonContainer {
    gap: 1rem;
    justify-content: flex-end;
    width: 100%;
  }
  .icon {
    font-size: 20px;
  }
  .text {
    font-size: 0.7rem;
  }
  .button {
    height: 2.2rem;
  }
}
