.bg {
  background-image: url("../../../public/bgImage.png");
  background-size: cover;
  height: 100dvh;
}

.leftImg {
  max-height: 80%;
  position: absolute;
  left: 0;
  bottom: 0;
  user-select: none;
  z-index: 0;
}

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #f3f4f8;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 999;
}

.title {
  font-size: 2rem;
  color: #f3f4f8;
  font-weight: 400;
  line-height: 40px;
}

.paragraph {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  margin-top: 12px;
  color: #f3f4f8;
}

.paragraph a {
  color: #f3f4f8;
  font-size: 0.725rem;
}
