.chatBoxCont {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  z-index: 92827367;
  background-color: #00000000;
  border-style: none;
  max-width: 440px;
  width: 440px;
  border-radius: 0 0 25px 25px;
  /* box-shadow: 0 0 6px 2px rgba(225, 225, 225, 0.511); */
  min-height: 82vh;
  max-height: 82vh;
  overflow-y: auto;
  font-size: 0.9rem;
}

.chatBoxCont .AllMessages {
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  scroll-behavior: smooth;
  overflow-y: auto;
  hyphens: auto;
}

.chatBoxCont .AllMessages::-webkit-scrollbar {
  display: none;
}

.chatBoxCont .AllMessages .userMessage {
  display: flex;
  flex-direction: column;
}

.chatBoxCont .sendMessage {
  width: 100%;
  margin-top: auto;
}

.messageBoxContainer {
  margin-top: 48px;
}

/* @keyframes typingAnimation {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
} */

@keyframes typingAnimation {
  0%, 30% {
    opacity: 0;
  }
  40%, 70% {
    opacity: 0.5;
  }
  80%, 100% {
    opacity: 1;
  }
}

.typingDot {
  animation: typingAnimation 1.5s infinite;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  fill: #78FFD5;
}



.nonTypingIcon {
  display: none;
}

@media (min-width: 1023px) {
  .chatBoxCont {
/*     min-width: 300px;
    max-width: 300px; */
    min-height: 81vh;
    max-height: 81vh;
    width: 30%;
  }


}
@media (min-width: 2000px) and (max-width: 3000px) {
  .chatBoxCont {
    min-width: 400px;
    max-width: 400px;
  }
}
@media (min-width: 1800px) and (max-width: 3000px) {
  .chatBoxCont {
    min-width: 370px;
    max-width: 370px;
  }
}
