.mobileContainer {
  width: 100vw;
  height: 100vh;
  background-image: url("../../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 17vh;
}
.headerP {
  color: #fff;
  margin-left: 20px;
  font-size: 2.5rem;
}
.body {
  display: flex;
  max-height: 60%;
}
.bodyP {
  position: absolute;
  align-self: center;
  width: 155px;
  left: 50%;
  font-size: 1.55rem;
  text-align: center;
  color: rgba(243, 244, 248, 1);
  word-break: keep-all;
}
.bodySpan {
  background-image: linear-gradient(215.25deg, #78ffd5, #007990);
  background-clip: text;
  -webkit-background-clip: text; /* For webkit browsers (e.g., Chrome, Safari) */
  color: transparent;
  font-size: 1.75rem;
  font-weight: 600;
}
.group {
  display: flex;
  width: 121px;
  height: 19.5px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.bottom {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
