.navPic img {
  object-fit: cover;
  border-radius: 90%;
  width: 50px;
  height: 50px;
  border: 3px solid rgb(41, 46, 52);
}
.navPicSec img {
  object-fit: cover;
  border-radius: 90%;
  width: 50px;
  height: 50px;
}
.profilePic img {
  object-fit: cover;
  border-radius: 90%;
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
}
.profilePic {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 200px;
  cursor: pointer;
  position: relative;
}
.profilePicLoader {
  position: absolute;
  top: 27%;
}
.profileLabel {
  width: 100%;
  cursor: pointer;
  text-align: center;
}

.profileLabel:hover {
  border-bottom: 1px solid black;
}
.inputFile {
  display: none;
}
