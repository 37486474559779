.container {
  display: flex;
  flex-direction: column;
}
.text {
  font-size: 1.2rem;
  padding: 30px 0 0 30px;
}
.buttonContainer {
  position: absolute;

  right: 0px;
  /* display: flex;
  justify-content: flex-end; */
  margin-top: 6rem;
  margin-right: 30px;
}

.downloadButton {
  padding: 10px 10px;
  margin: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #292e33;
  color: #ffffff;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.downloadButton:hover {
  cursor: pointer;
  background-color: #cdcdcd;
  color: #292e33;
}

.downloadButtonActive {
  cursor: pointer;
  background-color: #cdcdcd;
  color: #292e33;
}

.table {
  display: flex;
  padding-top: 1%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
/*  */

.listTable {
  border-collapse: collapse;
  color: #1a3060;
  width: 90%;
}

.listTable tbody {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.listTable tr {
  border-bottom: 1px solid #eee;
}

.listTable thead tr {
  border-bottom: 1px solid #333;
  color: #555;
}

.listTable thead tr td {
  font-size: 14pxem;
}

.listTable thead th {
  text-align: left;
  padding-left: 8px;
}

.listTable tr td {
  border-collapse: collapse;
  padding: 4px 4px;
  vertical-align: middle;
}

.listTable tbody td {
  min-width: 28px;
  max-width: 220px;
  font-size: 14px;
  text-align: left;
  height: 32px;
}

.listTable tbody tr:hover {
  color: #3d6caf;
  background-color: #eee;
}

.listTable th {
  text-align: left;
  padding-left: 8px;
}
