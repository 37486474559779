.container {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/bgImage.png");
  height: 100%;
  overflow-y: scroll;

  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}
.alert {
  width: 30%;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9999;
}
.title {
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: rgb(84, 83, 83);
  margin-bottom: 2rem;
}

.headOfP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.brand {
  font-weight: 600;
  font-size: 2rem;
  margin-left: 6px;
  color: rgb(255, 255, 255);
  letter-spacing: 0.1px;
}
.imageProcedura {
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 120px;
  margin-top: 3rem;
  object-fit: contain;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 2rem;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 0.2rem;
  gap: 1;
}
.secondInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 0 0;
  gap: 1rem;
}
.second {
  margin-top: 8px;
}
.basicInput {
  width: 293px;
  height: 42px;
  border: none;
  padding-left: 13px;
  font-size: 12px;
  position: relative;
  color: #f3f4f88f;

  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  border-radius: 2px;
  z-index: 2;
}

input.basicInput:-webkit-autofill {
  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
}
.basicInput::placeholder {
  color: #f3f4f88f;
}

.buttonContainer {
  margin: 2rem 0 0 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button {
  font-weight: 700;
  font-size: 14px;
  width: 111px;
  background: linear-gradient(
    90deg,
    rgba(8, 139, 136, 0.7) 0%,
    rgba(106, 240, 206, 0.7) 100%
  );
  height: 38px;
  cursor: pointer;
  color: #f3f4f8;
  border-style: none !important;
  border-radius: 2px;
}

.button:hover {
  background: linear-gradient(90deg, #088b88 0%, #6af0ce 100%);
  color: #f3f4f8;
}

.accountQuestion {
  text-align: center;
  color: #f3f4f8;
  font-weight: 400;
  font-size: 12px;
  margin: 1rem 0;
}

.buttonSign {
  font-size: 12px;
  font-weight: 400;
  color: rgba(120, 255, 213, 1);
  cursor: pointer;
  text-decoration: none;
}
.buttonSign:hover {
  text-decoration: underline;
}

.basicInput3 {
  width: 49%;
  position: relative;

  z-index: 2;
}
.basicLeft {
  margin-left: 8px;
}

.spanLeft {
  margin-left: 20px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

/* google facebook twitter */
.goole {
  width: 10%;
  height: 10%;
  margin: auto;
}

.twitter {
  width: 11.5%;
  height: 11.5%;
  margin: auto;
}

.twitter:hover {
  cursor: pointer;
  width: 12%;
  height: 12%;
}

.goole:hover {
  cursor: pointer;
  width: 11%;
  height: 11%;
}

.basicInput.missing {
  background: linear-gradient(90deg, #740202 0%, #900909 100%);
  /* border: 1.5px solid #9a00039e !important; */
  box-shadow: 2px 2px 4px rgba(255, 5, 9, 0.1);
}

.error {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}
/* email check */
.emailCheck {
  position: absolute;
  color: #faf947cc;
  font-size: 12px;
  margin-top: -24px;
  margin-left: 300px;
}
/* //password */
.showPassword {
  position: absolute;
  margin-top: -30px;
  margin-left: 255px;
  cursor: pointer;
  z-index: 18;
}
/* caps lock */
.capsLock {
  position: absolute;
  margin-top: -30px;
  margin-left: 310px;
  z-index: 18;
}

.line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 20px 0 20px 0;
  color: #f3f4f8;
}

.hrLine {
  width: 20%;
  height: 0;
}
.or {
  font-size: 12px;
  color: #f3f4f8;
}
/* password check */
.passwordCheck {
  position: absolute;
  margin-left: 350px;
  margin-top: -30px;

  color: #faf947cc;
  font-size: 12px;
  width: 234px;
}
.passwordCont {
  margin-bottom: 10px;
}
/* @media (max-width: 640px) {
  .loginContainer {
    width: 80%;
    margin: auto;
    position: relative;
    padding: 0px;
  }
  .title {
    font-size: 1.5rem;
    padding-bottom: 10px;
  }
  .brand {
    font-size: 2rem;
  }
  .registerContainer {
    padding-left: 1rem;

    padding-right: 1rem;
  }
  .inputRow {
    text-align: center;
  }
  .basicInput {
    width: 92%;
  }
  .basicInput3 {
    width: 44.8%;
  }
  .container2 {
    background-position: -500px !important;
  }

  .registerContainer {
    padding-bottom: 70px !important;

    padding-top: 30px !important;
  }

  .container {
    background-position: -400px !important;
  }
  .buttonContainer {
    align-items: center;
  }
  .button {
    width: 80%;
  }
} */
/* dropdown */
.dropdownMainContainer {
  display: flex;
  flex-direction: column;
}
.dropdownButton {
  width: 293px;
  height: 42px;
  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  border-style: none;
  border-radius: 2px;
  color: rgba(243, 244, 248, 0.56);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  /* margin-right: 28px; */
}
.optionsContainer {
  width: 293px;
  height: 248px;
  overflow-y: scroll;
  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  padding: 10px;
  border-radius: 2px;
  margin: 10px 0 0;
}
.optionsContainer::-webkit-scrollbar {
  padding: 10px;
  width: 2px;
}
.optionsContainer::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.optionsContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(360deg, #007990 -0.82%, #78ffd5 100.18%);

  border-radius: 8px;
}
.optionsContainer::-webkit-scrollbar-thumb:hover {
  background: #005b6b;
}
.option {
  width: Fixed (143px);
  height: Fixed (11px);
  font-size: 12px;
  font-weight: 500;
  color: rgba(243, 244, 248, 0.6);
  padding: 5px;
  border-bottom: solid 1px rgba(243, 244, 248, 0.6);
  cursor: pointer;
}
.option:hover {
  color: #78ffd5;
}

/*  */
.customStyle {
  background-color: red;
}
