.metaButtons {
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 6px;
  width: 100%;
  max-height: 60px;
}

.metaButtons p {
  text-align: center;
  color: #ffff;
  font-size: 0.7rem;
  position: absolute;
  top: 80%;
  left: 47%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.metaButtons .metaImg {
  position: relative;
  object-fit: contain;
  border-radius: 5px;
}

.metaButtons:hover {
  cursor: pointer;
}

.metaButtons:hover p {
  text-align: center;
  color: #ffff;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 47%;
  z-index: 2;
}
/* Large Buttons */
.metaButtonsLarge {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 6px;
  min-width: 150px;
  padding-left: 10px;
}

.metaButtonsLarge p {
  color: #fff;
  word-break: none;
  font-size: 0.8rem;
  padding-top: 25px;
  padding-left: 10px;
}

.metaButtonsLarge .metaImgLarge {
  border-radius: 6px;
  position: relative;
  height: 70px;
  border-radius: 5px;
  object-fit: contain;
  cursor: text;
}
