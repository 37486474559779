.Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1;
/*   background-color: #111111; */
  margin: 5px 20px;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  max-width: 420px;
  min-height: 72px;
  max-height: max-content;
}
.child {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
