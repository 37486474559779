.videoGrid {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  /* gap: 1.5rem; */
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 65vh;
}
