.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.pointer {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.label {
  flex: 0 0 200px;
  font-weight: bold;
}

.value {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right {
  text-align: right;
}

.infoContainer div {
  margin-top: 1rem;
  padding: 0 1rem;
}

@media (max-width: 1091px) {
  .value {
    all: unset;
  }

  .row {
    flex-direction: column;
    gap: 0rem;
    align-items: unset;
  }

  .row span {
    margin-top: 0.25rem;
  }

  .label,
  .right {
    all: unset;
  }

  .label {
    font-weight: bold;
  }
}
