.container {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/bgImage.png");
  height: 100%;
  overflow-y: scroll;

  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.title {
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: rgb(84, 83, 83);
  margin-bottom: 2rem;
}

.headOfP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.brand {
  font-weight: 600;
  font-size: 2rem;
  margin-left: 6px;
  color: rgb(255, 255, 255);
  letter-spacing: 0.1px;
}

.imageProcedura {
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 120px;
  margin-top: 3rem;
  object-fit: contain;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}
.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin: 0 0 2rem;
}
.inputContainer {
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}

.second {
  margin-top: 20px;
  padding-bottom: 0.4rem;
}
.basicInput {
  width: 293px;
  height: 42px;
  border: none;
  padding-left: 13px;
  font-size: 12px;
  position: relative;
  color: #f3f4f88f;

  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  border-radius: 2px;
  z-index: 2;
}

input.basicInput:-webkit-autofill {
  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
}
.basicInput::placeholder {
  color: #f3f4f88f;
}

.linkContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 1rem;
  width: 100%;
}
.forgot {
  width: 140px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #78ffd5;
  line-height: 18px;

  text-decoration: none;
}
.forgot:hover {
  text-decoration: underline;
}

.buttonContainer {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loginButton {
  font-weight: 700;
  font-size: 14px;
  width: 110px;
  background: linear-gradient(
    90deg,
    rgba(8, 139, 136, 0.7) 0%,
    rgba(106, 240, 206, 0.7) 100%
  );
  height: 38px;
  cursor: pointer;
  color: #f3f4f8;
  border-style: none !important;
  border-radius: 2px;
}

.loginButton:hover {
  background: linear-gradient(90deg, #088b88 0%, #6af0ce 100%);
  color: #f3f4f8;
}

.accountQuestion {
  text-align: center;
  color: #f3f4f8;
  font-weight: 400;
  font-size: 12px;
  margin: 1rem 0;
}
.buttonSign {
  font-size: 12px;
  font-weight: 400;
  color: rgba(120, 255, 213, 1);
  cursor: pointer;
  text-decoration: none;
}
.buttonSign:hover {
  text-decoration: underline;
}
.captcha {
  display: flex;
  padding-top: 2%;
  justify-content: center;
}
.alert {
  width: 30%;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9999;
}

.basicInput.missing {
  background: linear-gradient(90deg, #740202 0%, #900909 100%);
  /* border: 1.5px solid #9a00039e !important; */
  box-shadow: 2px 2px 4px rgba(255, 5, 9, 0.1);
}
/* invalid input */
.invalidInputContainer {
  display: flex;
  color: #fe2020;
  width: 293px;
  height: 42px;
  border: none;
  font-size: 12px;
  position: relative;

  /* background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  ); */
  border-radius: 2px;
  z-index: 2;
}

.invalidInput {
  color: #fe2020;
  width: 293px;
  height: 42px;
  border: none;
  padding-left: 13px;
  font-size: 12px;
  position: relative;

  background: linear-gradient(
    106.4deg,
    rgba(0, 121, 144, 0.33) -14.95%,
    rgba(243, 244, 248, 0.33) 267.48%
  );
  border-radius: 2px;
  z-index: 2;
}

.invalidInputAlert {
  color: #fe2020;
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  height: max-content;
  margin-left: 300px;
  margin-top: 9px;
}
/* //password */
.showPassword {
  position: absolute;
  margin-top: -30px;
  margin-left: 255px;
  cursor: pointer;
  z-index: 18;
}
/* caps lock */
.capsLock {
  position: absolute;
  margin-top: -30px;
  margin-left: 315px;
  z-index: 18;
  cursor: pointer;
}

.line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 20px 0 20px 0;
  color: #f3f4f8;
}

.hrLine {
  width: 30%;
  height: 0;
}
.or {
  font-size: 12px;
  color: #f3f4f8;
}

@media (max-width: 640px) {
  .loginContainer {
    width: 80%;
    margin: auto;
    position: relative;
    padding: 0px;
    margin-top: 80px;
  }
  .title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .registerContainer {
    margin-left: 1rem;

    margin-right: 1rem;
  }
  .inputRow {
    text-align: center;
  }
  .basicInput {
    width: 92%;
  }
  .container {
    background-position: -400px !important;
  }
}
