.downloadContainer {
  margin: 2px;
  margin-right: 20px;
  margin-bottom: 6px;

  & p {
    font-size: 0.8rem;
  }
  & button {
    border-style: none;
    padding: 2px 8px 2px 8px;
    color: #D9D9D9;
    font-size: 0.65rem;

    &:focus {
      color: #151515;
      background-color: #D9D9D9;
    }
    &:hover {
      opacity: 0.9;
      cursor: pointer;
      color: #78FFD5;
    }

  }
  & .activeButton {
    background-color: #D9D9D9;
    color: #151515;
  } 
}

.downloadTitle {
  color: white;
  font-weight: 900;
}

.downloadButtons {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 5px;
}

.downloadMiniButton {
  border-radius: 0 0 0.75rem 0.75rem;
  min-width: 40px;
  background: linear-gradient(to bottom right, rgba(0, 121, 144, 0.33), rgba(243, 244, 248, 0.15));
}