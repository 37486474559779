.videoContainer {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  position: relative;
}

.image {
  width: 200px;
  max-width: 100%;
  object-fit: contain;
  align-self: center;
  flex: 1;
}
