.dropdownMenu {
  background-color: #151515 !important;
  color: #f3f4f8 !important;
  margin-top: 0.96rem;
  width: 230px;
  border-radius: 0px 0px 12px 12px;
  padding: 0 1.5rem;
}

.menuText {
  color: #f3f4f8;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.menuItem {
  padding: 8px 22px !important;
  justify-content: space-between !important;
  margin: 0 auto !important;
  padding: 8px 8px !important;
  border-radius: 8px !important;
}

.menuItem:hover {
  background-color: #d9d9d9 !important;
}

.menuItem:hover .menuText {
  color: #151515 !important;
}

.menuItem:hover .icon path {
  stroke: #151515 !important;
}

.username {
  margin-left: auto;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;

  padding: 8px 8px !important;

  cursor: pointer;
  background: var(
    --Hover-Gradient,
    linear-gradient(215deg, #78ffd5 -28.97%, #007990 126.12%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  transition: 0.1s ease background;
}

.username:hover {
  background: #78ffd5;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.username:focus {
  background-color: #f3f4f8;
}

.logout {
  color: #209fa7;
}
