.credentials {
	width: 80vw;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1rem;
	height: auto;
	box-shadow: 0 0 6px 2px rgb(235, 233, 233);
	transition: transform 0.5s ease-in-out, height 0.5s ease;
	border-radius: 17px;
	background-color: rgb(181, 185, 199);
	margin: 1rem auto;
}
.credentials img {
	padding: 1rem;
	border-radius: 46px;
	box-shadow: 1px 4px 9px rgb(251, 248, 248);
	margin: auto 1rem;
}
.credentials .paraGreet {
	font-size: 3rem;
	font-weight: 600;
	justify-self: center;
	color: rgb(46, 47, 50);
}
