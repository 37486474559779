.assetContainer {
  height: 600px;
  overflow: hidden;
  margin-top: 1rem;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  border-bottom: 1px solid #cccccc;
}

.cardContainer {
  height: calc(
    100% - 100px
  ); /* Adjust the subtracted value based on actual height of .title */
  overflow-y: auto; /* Enables scrolling within cardContainer */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2%;
  margin-top: 2rem;
  padding-right: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 8rem;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(31, 31, 31, 0.75) 0%,
    rgba(54, 54, 54, 0.75) 50%,
    rgba(23, 23, 23, 0.75) 100%
  );
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  position: absolute;
  top: 10px;
  left: 7px;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
}

/* Pseudo-element for custom visual representation */
.checkbox::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent; /* Transparent background */
  border: 1px solid white; /* White border */
  border-radius: 4px; /* Rounded corners */
  z-index: -1; /* Place it behind the actual input for visual purposes */
  cursor: pointer;
}

/* Optional: Style for hover state */
.checkbox:hover::before {
  border: 3px solid white;
}

.checkbox:checked::after {
  content: "✔"; /* Unicode checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: white; /* White color for the checkmark */
}

.img {
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  color: white;
}

.bottom h3 {
  font-size: 1rem;
}

.bottom h4 {
  font-weight: 400;
  font-size: 0.75rem;
}

.pointer {
  cursor: pointer;
  rotate: 90deg;
}

.myLabel {
  display: block;
  font-size: xx-large;
  font-weight: 200;
  color: white;

  width: 100%;
  font-size: 1.3rem;
}

/* Scrollbar track */
.cardContainer::-webkit-scrollbar-track {
  background: linear-gradient(
    to bottom,
    #78ffd5 0%,
    #009fff var(--scroll-gradient-size),
    rgba(61, 61, 61, 0.3) var(--scroll-gradient-size)
  );
  border-radius: 10px;
}
/* Scrollbar width */
.cardContainer::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 80%;
}

.procedura {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;
  font-size: 20px;
}

.circle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 0.5rem;
  height: 2rem;
  color: #eeeeee;
  font-weight: 200;
}

.procedura, 
.myLabel {
  margin-bottom: 0.5rem;
}

@media (max-width: 1025px) {
  .cardContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}