.swiper {
  position: initial;
}

.swiper-wrapper {
  position: initial;
}

.swiper-horizontal > .swiper-pagination-bullets {
  bottom: 35px;
  z-index: 0;
}
