.inputCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #111111;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 1rem;
  min-width: 100%;
}

.inputCont svg:hover {
  cursor: pointer;
  opacity: 0.7;
  transform: rotate(-2deg);
}
.inputCont svg {
  color: #ffffff;
  font-size: 1.5rem;
}
.inputCont input.miuv {
  background-color: #111111 !important;
  border-bottom-left-radius: 6px;
  padding: 0.5rem 0.5rem 0.7rem 0.8rem;
  color: #ffffff !important;
  max-height: fit-content;
  border-style: none;
  outline: none;
  scroll-behavior: smooth;
  hyphens: auto;
}
