.ContainerSave {
  display: flex;
  align-self: flex-start;
  margin: 1rem;
}
button.modalSaveButton {
  display: block;
  cursor: pointer;
  border-radius: 0;
  font-size: 1rem;
  background-color: rgb(52, 54, 70);
  color: aliceblue;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: auto;
  padding: 1rem 3rem;
}
button.saveButton {
  display: block;
  cursor: pointer;
  border-radius: 0;
  margin-right: 5rem;
  font-size: 1rem;
  background-color: rgb(52, 54, 70);
  color: aliceblue;
  transition: background-color 0.3s ease, color 0.3s ease;
}
button.cancelButton {
  display: block;
  border-radius: 0;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  color: rgb(52, 54, 70);
  transition: background-color 0.3s ease, color 0.3s ease;
}
button.saveButton:hover {
  background-color: transparent;
  color: rgb(52, 54, 70);
}
button.cancelButton:hover {
  background-color: rgb(52, 54, 70);
  color: rgb(235, 235, 235);
}
button.saveButton {
  width: auto;
  padding: 1.5vh 7vw 1.5vh 7vw;
}
button.cancelButton {
  width: auto;
  padding: 1.5vh 7vw 1.5vh 7vw;
}
@media (max-width: 1400px) {
  button.saveButton,
  button.cancelButton {
    width: auto;
    padding: 2vh 5vw 2vh 5vw;
    font-size: 0.9rem;
  }
  button.saveButton {
    margin-right: 2rem;
  }
}
