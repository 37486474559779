.modalButtonContainer {
  height: 80px;
  width: 14px;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 120px;
  position: relative;
  cursor: pointer;
  align-self: flex-end;
}
.modalContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(41, 36, 36);
  color: aliceblue;
  margin: 5px 20px;
  padding: 1rem 1rem;
  border-radius: 6px;
  width: max-content;
  max-width: 53vw;
  max-height: 20vh;
  position: absolute;
  z-index: 100;
  left: 320px;
  bottom: 18vh;
  overflow-y: scroll;
  animation: slideFromLeft 0.7s ease-out forwards;
}

@keyframes slideFromLeft {
  0% {
    color: rgba(240, 248, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
.modalContainer::-webkit-scrollbar {
  width: 10px;
  height: 0;
}

.modalContainer::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 70px;
}

.modalContainer::-webkit-scrollbar-thumb {
  background: #392d2d;
  border-radius: 10px;
  border: 1px white solid;
}
@media (max-width: 1400px) {
  .modalContainer {
    left: 305px;
    bottom: 18vh;
  }
}
@media (min-width: 2000px) and (max-width: 3000px) {
  .modalContainer {
    left: 370px;
    bottom: 20vh;
  }
}
@media (min-width: 1800px) and (max-width: 3000px) {
  .modalContainer {
    left: 370px;
    bottom: 15vh;
  }
}
