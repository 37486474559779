.Large {
  border-style: none;
  max-height: 50px;
  color: #78ffd4bb;
  background-color: #f0f8ff;
  margin-bottom: 5px;
  margin-top: 5px;
  margin: 5px 3px;
/*   width: 200px; */
width: 100%;
  padding: 10px 0;
  border-radius: 6px;
  position: relative;
/*   background-color: #111111; */
background-color: transparent;
  font-weight: 700;
  border: #78ffd4c0 1px solid;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Medium {
  max-height: 60px;
  color: #78ffd4a4;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px 0;
  border-radius: 6px;
  width: 120px;
  position: relative;
  background-color: #111111;
  font-weight: 700;
  border: #78ffd4c0 1px solid;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Mini {
  max-height: 50px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #78ffd49f;
  padding: 10px 0;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 6px;
  width: 60px;
  position: relative;
  background-color: #111111;
  font-weight: 700;
  border: #78ffd4c0 1px solid;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Picked {
  border: 1px solid #d9d9d9;
  max-height: 50px;
  color: #d9d9d9;
  margin-bottom: 5px;
 /*  background-color: #111111; */
 background: transparent;
  font-weight: 700;
  margin-top: 5px;
  width: 250px;
  padding: 10px 0;
  position: relative;
  cursor: text;
}
.Large,
.Mini,
.Medium {
  border-width: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #08110e,
      #1c3b31,
      #397965,
      #397965,
      #397965,
      #397965,
      #397965,
      #1f4237,
      #08110e
    )
    1;
}
.Large:hover,
.Mini:hover,
.Medium:hover {
  border: 1px solid #78ffd5;
  border-radius: 0;
  border-color: #78ffd5;
  cursor: pointer;
}
