.videoSection {
  background-color: #eeeeee;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  width: 100%;
  height: calc(100% - 3rem);
  background-color: rgba(36, 36, 36, 0.4);
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.buttonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regenerateButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #2d3648;
  color: #fff;
  padding: 0.7rem 1rem;
  z-index: 1;
  margin-left: 5px;
}

@media (max-width: 991px) {
  .regenerateButton span {
    display: none;
  }
}

.regenerateButton:hover {
  cursor: pointer;
  background-color: rgb(45, 54, 72, 0.9);
}

.replayIcon {
  font-size: 1.5rem;
  rotate: 65deg;
  transform: scaleX(-1);
  transition: 1s ease;
}

.regenerateButton:hover .replayIcon {
  rotate: 360deg;
}

.spinner {
  text-transform: uppercase;
  background: linear-gradient(215.25deg, #78ffd5 -28.97%, #007990 126.12%);
  font-size: 16px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
