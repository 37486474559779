.autoTextCont {
  margin-bottom: 10px;
}
.textCont {
  margin: 5px 24px;
  padding: 12px 20px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  min-width: min-content;
  width: 295px;
  background-color: #d9d9d9;
}
.metaCont {
  margin: 10px 20px;
}
.IconCont {
  width: 18px;
  height: 18px;
  margin: 5px 20px;
}

.IconDisable {
  display: none;
}

@media (min-width: 1023px) {
  .textCont {
   /*  width: 220px; */
   width: 70%;
  }
}