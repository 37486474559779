.button {
  display: inline-block;
  text-decoration: none;
  color: #f3f4f8;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(8, 139, 136, 0.7) 0%,
    rgba(106, 240, 206, 0.7) 100%
  );
  padding: 0.5rem 1.5rem;
  border-radius: 2px;
  margin-top: 2rem;
  text-transform: uppercase;
  font-weight: 700;
}

.button:hover {
  background: linear-gradient(90deg, #088b88 0%, #6af0ce 100%);
}
