.header {
  background-color: #131313;
}

.navbar {
  /* max-width: 2000px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  overflow: hidden;
  height: 10vh;
}

.logo {
  user-select: none;
}

.loginButton {
  color: #f3f4f8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 1.2rem;
}

.container {
  display: flex;
  align-items: center;
}

.substract {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  background: #272727;
  border-radius: 32px;
  padding: 1rem 2rem;
  height: 60px;
}

.coin {
  color: #f3f4f8;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 66.667% */
  text-transform: uppercase;
  margin-left: 0.5rem;
  user-select: none;
}

.profileAvatar {
  cursor: pointer;
}

.profileAvatar img {
  margin-left: auto;
  max-width: 61px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid;
  outline: 10px solid #131313;
  transform: translateX(50%);
  user-select: none;
}

.triangleIcon {
  z-index: 999;
  flex-shrink: 0;
}

.infoIcon {
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  scale: 1.5;
}

.infoIcon:hover path {
  fill-opacity: 1 !important;
  stroke-opacity: 1 !important;
}

.infoIcon:active path:nth-of-type(1) {
  stroke: url(#paint0_linear_1_1300);
}

.infoIcon:active path:nth-of-type(2) {
  fill: url(#paint0_linear_1_1300);
}

.triangleIcon {
  cursor: pointer;
}

.coinIcon {
  cursor: pointer;
  flex-shrink: 0;
}

.coinIcon:hover path {
  fill: #78ffd5 !important;
}
.coinIcon:active path {
  fill: #ffffff !important;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .logo {
    max-width: 190px;
  }

  .substract {
    padding: 1rem;
  }

  .triangleIcon,
  .infoIcon {
    display: none;
  }

  .coin {
    font-size: 20px;
  }
}
